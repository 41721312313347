// VARIABLES
// =================================================
$primary: #f59da7
$white: #fff
$color-bullets: $primary

// MIXIN - PREFIX
// =================================================
@mixin prefix($property, $value, $prefixes: webkit moz ms o)
  @each $prefix in $prefixes
    #{'-' + $prefix + '-' + $property}: $value

    #{$property}: $value

// MIXIN - ANIMATION AND KEYFRAMES
// =================================================
@mixin keyframes($animationName)
  @-webkit-keyframes #{$animationName}
    @content

  @-moz-keyframes #{$animationName}

  @-o-keyframes #{$animationName}

  @keyframes #{$animationName}

@mixin animation($animate...)
  $max: length($animate)
  $animations: ""
  @for $i from 1 through $max
    $animations: #{$animations + nth($animate, $i)}
    @if $i < $max
      $animations: #{$animations + ", "}

      -webkit-animation: $animations
      -moz-animation: $animations
      -ms-animation: $animations
      animation: $animations

// KEYFRAMES
// =================================================
@include keyframes(progress)
  0% 
  stroke-dashoffset: -75
  opacity: 1

  95% 
  stroke-dashoffset: 0
  opacity: 1

  100% 
  opacity: 0
  stroke-dashoffset: 0

// GENERAL
// html,
// body
//   height: 100%
//   display: flex
//   justify-content: center
//   align-items: center
//   position: relative

// body
//   background: $white
//   font-family: Helvetica Neue, Helvetica, Arial, sans-serif
//   font-size: 14px
//   color: $primary
//   margin: 0
//   padding: 0

// .swiper-container
//   width: 100%
//   height: 100%

// .swiper-slide
//   text-align: center
//   font-size: 18px
//   background-color: $white
//   display: flex
//   justify-content: center
//   align-items: center

// SWIPER
// #slider .swiper-pagination-bullet,
// #client_Comments .swiper-pagination-bullet,
.bullet_svg .swiper-pagination-bullet
  background-color: transparent
  opacity: 0.7

  // bullet svg animation
  &--svg-animation
    width: 26px
    height: 26px
    margin: 0 6px !important
    display: inline-block

    svg
      @include prefix(transform, rotate(-90deg))

    .svg__circle-inner
      stroke: $color-bullets
      fill: transparent
      @include prefix(transition, all 0.7s ease)

  &-active
    .svg__circle
      stroke: $color-bullets
      stroke-dasharray: 75
      stroke-dashoffset: 0
      @include animation(progress 7s ease-in-out 1 forwards)

    .svg__circle-inner
      fill: $color-bullets
// #slider .swiper-pagination,
// #client_Comments .swiper-pagination,
.bullet_svg .swiper
  position: relative

.bullet_svg .swiper-pagination
  // position: absolute !important
  width: fit-content
  height: fit-content
  // top: 50% !important
  // left: 87% !important
  // transform: rotate(90deg) !important
