:root {
  --Body-bg: #EEF0F8;
  --Primary-color: #ef3e53;
  --Secondary-color: #a0b6c6;
  --Secondary-Light-color: #d9eaf6;
  --Deem-secondary-color: #5b768a;
  --DoubleDeem-secondary-color: #323f4a;
  --Deem-primary-color: #630618;
  --softify-primary-color: #134B77;
  --softify-secondary-color: #00B7AA;
  --Shadow-color: #0dbfa9;
  --Color-1: #A0B6C7;

}

.services_title_box {
  transition: all 0s ease-in-out !important;
  -webkit-transition: all 0s ease-in-out !important;
  -moz-transition: all 0s ease-in-out !important;
  -ms-transition: all 0s ease-in-out !important;
  -o-transition: all 0s ease-in-out !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.services_content_box {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.services_content_hidden {
  opacity: 0;
  visibility: hidden;
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  /* font-size: 1.5rem; */
}


.active2 {
  font-weight: bold;
}

/* navbar component starts  */

#Modal_Wrapper {
  /* height: 100%;
  width: 100%; */
  /* position: fixed; */
  transition: transform 0.5s ease-in-out, visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
  inset: 0;
  position: fixed;
  top: 0;
}

.modal {
  transition: transform 0.4s ease-in-out, visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
}


.Modal_Wrapper .Modalvisible {
  opacity: 1;
  visibility: visible;
  transform: translate(-70%, -10rem);
}

.Modal_Wrapper .ModalInvisible {
  transform: translate(-100%, -10rem);
  visibility: hidden;
  opacity: 0;
}



.contact_header p {
  /* margin: 0 1rem; */
  transition: 0.3s ease-in-out;
  color: #134B77;
}

.contact_header p:hover {
  cursor: pointer;
  color: #00B7AA;
}



.menu_bar {
  /* display: flex; */
  list-style: none;
  /* margin: 0;
  padding: 0;
  font-size: 1.045rem; */
}

.menu_bar li .a {
  text-decoration: none;
  color: #134B77;
  font-weight: 500;
  /* padding: 1.5rem 0; */
  position: relative;
  display: block;

}

.menu_bar li .a::before {
  content: "";
  position: absolute;
  /* top: -2px;
  inset-inline-start: -2px;
  inset-inline-end: -2px; */
  inset-block-end: 2px;
  block-size: 0.3rem;
  inline-size: 0;
  visibility: hidden;
  opacity: 0;
  background: #00B7AA;
  transition: all 0.7s ease-in-out;
}

.menu_bar li .a:hover:before {
  content: "";
  inline-size: 100%;
  opacity: 1;
  visibility: visible;
}

.scrolltainer {
  inline-size: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: #134B77;

}

.header1 {
  /* display: flex; */
  color: #FFFFFF;
  /* padding: 0; */
}



.scrolltainer .header1 .menu_bar li .a {
  color: #FFFFFF;
}

.stick {
  transition: all 0.5s ease-in;
  position: fixed;
  top: 0;
  /* block-size: 4.8rem; */
  transform: translateY(0rem);
  visibility: visible;
  opacity: 1;
  z-index: 40;
}

.hide {
  /* block-size: 0; */
  transform: translateY(-2rem);
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}

/* #mobile_section {
  block-size: 100vh;
  background: white;
} */

.mobile_parent_drop:hover .drop_down {
  display: block;
}



.mobile_navbar {
  transition: all 0.3s ease-in-out;
  position: absolute;
  opacity: 1;
  min-height: 100vh;
  scroll-behavior: smooth;
  visibility: visible;
  overflow-y: scroll;
  inline-size: 100vw;
  block-size: fit-content;
  top: 0;
  inset-block-end: 0;
}



.mobile_section {
  opacity: 0;
  overflow: scroll;
  position: absolute;
  inline-size: 0;
  inset-inline-start: 0;
  visibility: hidden;
}

/* navbar dropdown css starts */
.dropdown_id {
  transition: all 0.3s ease-in 0.05s, height 0.3s ease-in-out, visibility 0.1s ease-in, opacity 0.3s ease-in;
  /* transition:all 0.3s ease-in-out, opacity 0.1s ease-in-out; */
  visibility: visible;
  /* position: absolute;*/
  inline-size: 100%;
  background: #FFFFFF;
  opacity: 1;
  /* display: block; */
}

.dropdown_hidden {
  /* position: ; */
  background: #FFFFFF;
  inline-size: 100%;
  z-index: 0;
  visibility: hidden;
  /* opacity: 0; */
  block-size: 0;
  /* display: none; */

}

.mobile_menu_bar {
  position: absolute;
  width: 100%;
  height: 0;
  top: 60px;
  background: #000;
  left: 0;
  transition: height 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out 0.1s;
}

.mobile_menu_bar_invissible {
  height: 0rem;
  visibility: hidden;
  opacity: 0;

}

.mobile_menu_bar_vissible {
  height: 20rem;
  visibility: visible;
  opacity: 1;
}

.height_increase {
  height: 34rem;
}

/* navbar dropdown css ends */

/* navbar component ends  */

/* Home_main_component */

#main_component {
  margin-left: 6rem;
}

.main-banner-two {
  overflow: hidden;
  position: relative !important;
  z-index: 1;
}

.main-banner-two .creative-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.main-banner-two .creative-shape img {
  width: 100%;
}

.banner-with-background-img {
  background-image: url('images/front_slider.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* padding: 250px 0px 30px; */
  color: #fff;
}


.banner-with-background-img::before {
  background-color: #ee0e28;
  content: "";
  opacity: .70;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.banner-box {
  /* position: absolute; */
  position: relative;
  display: block;
  opacity: 1;
  visibility: visible;
  z-index: 10 !important;
}

/* 
:root {
    --color-primary: #0073ff;
    --color-white: #fff;
    --color-black: #141d28;
    --color-black-1: #212b38;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Times New Roman', Times, serif;

}

.menu_bar{
    background-color: var(--color-black);
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
}

.logo{
    color: var(--color-white);
    font-size: 38px;
}

.logo span{
    color: var(--color-primary);
} */

/* .menu_bar ul{
    list-style: none;
    display: flex;
}

.menu_bar ul li {
    padding: 10px 30px ;
    position: relative;
    transition: 1s;
    transition-timing-function: ease-in-out;
}

.menu_bar ul li a {
    color: var(--color-white);
    text-decoration: none;
    font-size: 20px;
    transition: all 0.3s;
}

.menu_bar ul li a:hover {
    color: var(--color-primary);
}

.dropdown_menu{
    display: none;
    
}

.menu_bar ul li:hover .dropdown_menu{
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
   
}

.menu_bar ul li:hover .dropdown_menu ul{
    display: block;
    margin:10px  ;
    z-index: 100;
}

.menu_bar ul li:hover .dropdown_menu ul li{
    width: 150px;
    padding: 20px;
}

.dropdown_menu_1{
    display: none;
}
  */


.dorpdown_parent {
  position: relative;
}

.dorpdown_child {
  
  background: white;
  transform: translateY(1rem);
  position: absolute;
  transition: all 0.5s ease-in-out 0.2s;
  -webkit-transition: all 0.5s ease-in-out 0.2s;
  -moz-transition: all 0.5s ease-in-out 0.2s;
  -ms-transition: all 0.5s ease-in-out 0.2s;
  -o-transition: all 0.5s ease-in-out 0.2s;
  -webkit-transform: translateY(1rem);
  -moz-transform: translateY(1rem);
  -ms-transform: translateY(1rem);
  -o-transform: translateY(1rem);
}

.dorpdown_child ul {
  transition: all 0.5s ease-in-out 0.2s;
  -webkit-transition: all 0.5s ease-in-out 0.2s;
  -moz-transition: all 0.5s ease-in-out 0.2s;
  -ms-transition: all 0.5s ease-in-out 0.2s;
  -o-transition: all 0.5s ease-in-out 0.2s;
}


.dropdown_hide {
  transform: translateY(4rem);
  -webkit-transform: translateY(4rem);
  -moz-transform: translateY(4rem);
  -ms-transform: translateY(4rem);
  -o-transform: translateY(4rem);
  visibility: hidden;
  opacity: 0;
  z-index: -10000;
  
}

/* .dorpdown_parent .dorpdown_child ul li {
  visibility: hidden;
  opacity: 0;
  z-index: -100;
} */


.dorpdown_child ul li a {
  display: block;
  /* visibility: hidden;
  opacity: 0;
  z-index: -100; */
}





/* .dorpdown_parent:hover .dorpdown_child {
  visibility: visible;
  z-index:100;
  position: absolute;
  transform: translateY(1rem);
  opacity: 1;
  -webkit-transform: translateY(1rem);
  -moz-transform: translateY(1rem);
  -ms-transform: translateY(1rem);
  -o-transform: translateY(1rem);
}

.dorpdown_parent:hover .dorpdown_child ul {
  visibility: visible;
  opacity: 1;
  z-index: -100;


}

.dorpdown_parent:hover .dorpdown_child ul li {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

.dorpdown_parent:hover .dorpdown_child ul li a {
  visibility: visible;
  opacity: 1;
  z-index: 100;
} */




.round_vanila_box {
  transform: rotate(45deg);
  background-image: white;
  border-radius: 2rem;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  /* clip-path: polygon(44% 4%, 100% 0, 0 100%, 4% 44%, 0 0); */
  /* clip-path: polygon(39% 6%, 64% 6%, 100% 0, 0 100%, 6% 64%, 6% 39%, 0 0); */
  /* clip-path: polygon(39% 6%, 64% 6%, 100% 8%, 8% 100%, 5% 64%, 4% 46%, 0 0); */
  border-bottom-right-radius: 2rem;
  transition: all 0.5s ease-in-out;
}

.round_vanila_box .icon {
  transform: rotate(-45deg);
}

.vanila_card {
  transition: all 0.5s ease-in-out;
  background: #FFFFFF;
}

.vanila_card img {
  background-color: #FFFFFF;
  inline-size: 8rem;
  padding: 1rem;
  margin-block-start: 1rem;
  transition: all 0.2s ease-in-out;
}

.vanila_card:hover {
  background-color: #00B7AA;
  color: #FFFFFF;
}

.vanila_card:hover img {
  border-radius: 0.25rem;
  transform: scale(0.8);
}

.vanila_card:hover .round_vanila_box {
  background-color: #00B7AA;
}

.vanila_card:hover .round_vanila_box .icon {
  color: white;
}

/* #client_Comments {
  background-image: url("./images/background_1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: var(--Primary-color);

} */

#client_Comments #first_child_client_Comments {
  background: var(--Primary-color);


}

/* .sub_child_client_Comments {
  position: relative;
  top: -10rem;
} */

#client_Comments .comment .more {
  display: block;
  transition: visibility 0.15s ease-in-out, height 0.3s ease-in-out, opacity 0.2s ease-in-out;
}

#client_Comments .comment .more.display_more {
  opacity: 1;
  visibility: visible;
  height: 100px;

}

#client_Comments .comment .more.display_none {
  visibility: hidden;
  height: 0;
  opacity: 0;

}

#cli #why_Chose_us .details {
  background-image: url("./images/map.svg") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.WHAT_WE_DO_BOX:hover .vanila_underline {
  inline-size: 6rem;
}



/* freequiently ask question Main Componenent */
.Frequently_ask_box {
  overflow: hidden;
  /* visibility: hidden; */
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out 0.15s;
  block-size: 0;
  opacity: 0;
}

.Frequently_ask_box_vissible {
  /* visibility: visible; */
  overflow: visible;
  opacity: 1;
  height: 10rem;
}

/* services page */

/* .services_title_box {
  transition: color 0.2s ease 0 !important;
  -webkit-transition: color 0.2s ease 0 !important;
  -moz-transition: color 0.2s ease 0 !important;
  -ms-transition: color 0.2s ease 0 !important;
  -o-transition: color 0.2s ease 0 !important;
} */

/*  blog area in Main Componenent  */
/* #blog_area :;
  -webkit-transition:;
  -moz-transition:;
  -ms-transition:;
  -o-transition:;
{
  background: #F9F9FF;
} */



/* Main Componenet ends  */

/* Footer section starts  */
#footer {
  position: relative;
  background-image: url("./images/footer-bg-1.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

#footer::before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  inline-size: 100%;
  block-size: 100%;
  /* background: #164B77; */
  background: var(--Deem-primary-color);
  opacity: 0.9;
}

.Child_content {
  position: relative;
  opacity: 1;

}

.footer_service_list {
  transition: all 0.5s ease-in-out;
}

.footer_hover:hover .footer_service_list {
  transform: translateX(10px);
  color: var(--Primary-color);
}

.footer_text_hover_parent {
  transition: all 0.4s ease-in-out;
}

.footer_text_hover_parent:hover {
  color: var(--Primary-color);
}


.footer_Input_email:focus {
  outline: none;
  box-shadow: 0;
}



/* Footer section ends  */



/* OUr Products section swiper clicked effects in homepage  */
#our_products {
  transition: all 0.71s ease-in-out;
}

.product_content_box {
  transition: all 0.71s ease-in-out;
}

.text_content_anime_off {
  opacity: 0;
  visibility: hidden;
  transform: translateY(2rem);
}

.text_content_anime_on {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.text_content {
  transition: all 0.3s ease-in-out 1s;

}

.all_products {
  border-radius: 0.5rem;
}

.clicked_product {
  box-shadow: 0px 0px 16px 4px rgba(0, 183, 170, 0.08);
  border: 1px solid var(--Primary-color);
  border-radius: 0.25rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.horizontal_writing {
  writing-mode: horizontal-tb;
}

.vanila_Swiper_Corner {
  clip-path: polygon(50% 0, 100% 50%, 50% 100%);
}

.vanila_products_hidden {
  position: absolute;
  bottom: 0;
  /* z-index: -2; */
  visibility: hidden;
  opacity: 0;
}

.change_container {
  transition: all 0.5s ease-in-out;
}

.vanila_products_vissible {
  position: relative;
  top: 0;
  visibility: visible;
  opacity: 1;
  z-index: 2;
}

.breadcrumbs_bg {
  position: relative;
  background-image: url("./images/ContactUs_bg_1.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.breadcrumbs_bg::before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  inline-size: 100%;
  block-size: 100%;
  /* background: #164B77; */
  background: var(--Secondary-color);
  opacity: 0.5;
}

.breadcrumbs {
  opacity: 1;
  border: 1px solid #cbd2d9;
  border-radius: 0.3rem;
  display: inline-flex;
  overflow: hidden;
}

.breadcrumbs__item {
  background: #fff;
  color: #333;
  outline: none;
  padding: 0.75em 0.75em 0.75em 1.25em;
  position: relative;
  text-decoration: none;
  transition: background 0.2s linear;
}

.breadcrumbs__item:hover:after,
.breadcrumbs__item:hover {
  background: #edf1f5;
}

.breadcrumbs__item:focus:after,
.breadcrumbs__item:focus,
.breadcrumbs__item.is-active:focus {
  background: #323f4a;
  color: #fff;
}

.breadcrumbs__item:after,
.breadcrumbs__item:before {
  background: white;
  bottom: 0;
  clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  transition: background 0.2s linear;
  width: 1em;
  z-index: 1;
}

.breadcrumbs__item:before {
  background: #cbd2d9;
  margin-left: 1px;
}

.breadcrumbs__item:last-child {
  border-right: none;
}

.breadcrumbs__item.is-active {
  background: #edf1f5;
}



/* component pages ispDigitals starts */
/* #bradcrumbs {
  box-shadow: 0px 0px 2px rgba(22, 75, 119, 0.1);
  border-radius: 0.25rem;
  opacity: 1;

} */




/* .home-icon {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
  border-radius: 0.25rem;

}

.home-icon::before {
  position: absolute;
  content: "";
  inline-size: 100%;
  block-size: 100%;
  background: var(--Primary-color);
  clip-path: polygon(0% 0%, 67% 0%, 100% 50%, 67% 100%, 0% 100%);
  z-index: -5;
  top: -1%;
  inset-inline-start: -1%;
  border-radius: 0.25rem;
}

.home-icon::after {
  position: absolute;
  content: "";
  inline-size: 100%;
  block-size: 100%;
  background: var(--Secondary-color);
  clip-path: polygon(0% 0%, 67% 0%, 100% 50%, 67% 100%, 0% 100%);
  z-index: -6;
  top: -1%;
  inset-inline-start: 10%;
  border-radius: 0.25rem;
}

.breadcrumbs-icon {
  position: relative;
}

.breadcrumbs-icon::before {
  position: absolute;
  content: "";
  background-image: url("./images/breadcrumbs-icon.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  inline-size: 30%;
  block-size: 100%;
  top: 0;
  inset-inline-end: -20%;
} */

/* component pages ispDigitals ends  */

/* partnership page  */
#partnership {

  position: relative;
  /* background-image: url("./images/partnership_0.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  block-size: fit-content;
  inline-size: 100%;
}

#partnership::before {
  content: "";
  inset-inline-start: -0;
  inset-inline-start: 0;
  top: 0;
  z-index: -1;
  inline-size: 100%;
  block-size: 100%;
  background: linear-gradient(180deg, rgba(22, 75, 119, 0.9) 0%, rgba(0, 183, 170, 0.9) 98.77%);
  position: absolute;

}


/* Life at sotfybd section starts */
/* .lg-react-element {
  display: flex;
  float: inline-start;
  flex-wrap: wrap;
}
.web_gallery{
  display: inline-block;
}*/
.web_gallery img {
  /* display: inline-block;
  inline-size: 20rem; */
  vertical-align: top;
  margin: 0.2rem;
  transition: 0.3s ease-in-out;
  filter: grayscale(100%);
}

.web_gallery img:hover {
  filter: grayscale(0);
  /* filter: blur(5px); */
}

/* Life at sotfybd section ends */
@media (min-inline-size: 768px) {

  .Frequently_ask_box_vissible {
    /* visibility: visible; */
    block-size: 6.5rem;
    overflow: visible;
    opacity: 1;
  }

}


/* dom reload animation and scrolling */
#Platform_1 img {
  /* animation: leftoright 3s ease-in-out 0 infinite; */
  animation: linear infinite alternate;
  animation-name: leftoright;
  animation-duration: 5s;
}

.myanime {
  /* transform: translateY(0px); */
  animation-name: myanime;
  animation-duration: 3s;
  animation-delay: 0;
  animation-timing-function: ease-in-out;
}


@keyframes visibility_anime {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  90% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes product_box {
  0% {
    opacity: 0;
    transform: translateY(1.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@keyframes myanime {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes leftoright {
  0% {
    transform: translateX(0rem);
  }

  50% {
    transform: translateX(-5rem);
  }

  100% {
    transform: translateX(0rem);
  }
}