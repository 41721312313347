:root {
    --Body-bg: #EEF0F8;
    --Primary-color: #ef3e53;
    --Secondary-color: #a0b6c6;
    --Secondary-Light-color: #d9eaf6;
    --Deem-secondary-color: #5b768a;
    --DoubleDeem-secondary-color: #323f4a;
    --Deem-primary-color: #630618;
    --softify-primary-color: #134B77;
    --softify-secondary-color: #00B7AA;
    --Shadow-color: #0dbfa9;
  
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.counter-bar {
    padding-top: 20px;
}

.counter-bar .skill-item {
    margin-top: 30px;
}

.counter-bar .skill-item .title {
    font-size: 14px;
    line-height: 45px;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    color: #120e1d;
}

.counter-bar .skill-item .skill-bar .bar-inner {
    width: 100%;
    height: 6px;
    border-radius: 0px;
    background-color: transparent;
    position: relative;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    border-radius: 0px;
    background: var(--Primary-color);
    transition: all 0.5s ease;
    transition-delay: 0s;
    width: 0;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent {
    position: absolute;
    top: -45px;
    right: -30px;
    font-size: 12px;
    color: #ffffff;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent::after {
    content: "";
    position: absolute;
    left: -7px;
    top: -2px;
    width: 40px;
    height: 25px;
    background: #0e0e0e;
    line-height: 20px;
    text-align: center;
    z-index: -1;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent::before {
    position: absolute;
    content: "";
    border-top: 20px solid #0e0e0e;
    width: 0;
    height: 0;
    bottom: -12px;
    left: 0;
    border-right: 15px solid transparent;
    transform: translateX(-50%);
    z-index: -1;
}