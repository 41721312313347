@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .section_header{
    @apply text-web-color text-4xl md:text-5xl  font-bold text-center;
  }
  .container_header_white{
    @apply text-white text-2xl md:text-4xl font-bold ;
  }
}
:root {
  --Body-bg:#EEF0F8;
  --Primary-color: #ef3e53;
  --Secondary-color: #a0b6c6;
  --Secondary-Light-color: #d9eaf6;
  --Deem-secondary-color: #5b768a;
  --softify-primary-color: #134B77;
  --softify-secondary-color: #00B7AA;
  --Shadow-color: #0dbfa9;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --tw-bg-opacity: 1;
  /* background-color: #EEF0F8; */
  background-color: #FFFFFF;
  /* background-color: white; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.active{
  font: bolder;
  color: white;
}
.text-web-color {
  color: var(--Primary-color);
}

.secondary-text {
  color: var(--Secondary-color);
}

.text-web-color-hover {
  color: #134B77;
  transition: color 0.3s ease-in-out;
}

.text-web-color-hover:hover {
  color: #00B7AA;
}

.text-cyan {
  color: #00B7AA;
}

.primary-bg{
  background-color: var(--Primary-color);
}
.secondary-bg{
  background-color: var(--Secondary-color);
}

.vanila_btn_hover_1 {
  background: var(--Secondary-color);
  color: white;
}

.vanila_btn_hover_2 {
  color: white;
  background: var(--Primary-color);
}
.vanila_btn_hover_3{
  color: white;
  background-color: transparent;
  border:2px solid var(--Primary-color);
}

.vanila_btn_hover_1,
.vanila_btn_hover_2,
.vanila_btn_hover_3 {
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
}

.vanila_btn_hover_1:hover {
  background: var(--Primary-color);
}

.vanila_btn_hover_2:hover {
  background: var(--Secondary-color);
}
.vanila_btn_hover_3:hover {
  background: var(--Primary-color);
}

.vanila_underline {
  height: 0.15rem;
  width: 4rem;
  background-color: var(--Secondary-color);
  transition: all 0.3s ease-in-out;
}

.vanila_text_hover_1:hover {
  color: var(--Secondary-color);
  transition: color 0.3s ease-in-out;
}

.vanila_bg_color_1 {
  background: var(--Primary-color);
}

.vanila_bg_color_2 {
  background: var(--Primary-color);
}

.vanila_bg_color_3 {
  background: var(--Secondary-color);
}


.vanila_border_color_1 {
  border-color: var(--Secondary-color);
}

.myborder {
  transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
}