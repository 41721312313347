:root {
    --Primary-color: #ef3e53;
    --Secondary-color: #a0b6c6;
    --Deem-primary-color: #6a000d;
    --softify-primary-color: #134B77;
    --softify-secondary-color: #00B7AA;
}


#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}




#slider .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
}

#slider .swiper-slide {
    color: #fff;
    border-radius: 0.5rem;
    position: relative;
}




#slider .fadeinleft,
#slider .fadeinright,
#slider .fadeindown {
    visibility: hidden;
}



#slider .swiper-slide-active .fadeinleft {
    animation: fadeInLeft 3s;
    /* animation-delay: 1s; */
    visibility: visible;
}

#slider .swiper-slide-active .fadeinright {
    animation: fadeInRight 3s;
    /* animation-delay: 1s; */
    visibility: visible;
}

#slider .swiper-slide-active .fadeindown {
    animation: fadeInDown 3s;
    /* animation-delay: 1s; */
    visibility: visible;
}


@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(3rem);
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-3rem);
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}





.vertical_swiper .swiper {
    width: auto;
    height: auto;
    background: #EEEEEE;

    /* background:; */
}

.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none;
}

.vertical_swiper .swiper .swiper-slide {
    padding-top: 2rem;
    padding: 2rem;
    /* margin: 0rem; */
    margin-bottom: 1rem;
    position: relative;
    /* text-align: center;
    font-size: 18px; */
    background: #EEEEEE;
    /* overflow: hidden; */
    /* Center slide text vertically */
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.vanila_Swiper_Corner {
    position: absolute;
    /* right: -; */
}

.vertical_swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
    /* filter: grayscale(100%); */
}

.vertical_swiper .swiper-slide div:hover img {
    filter: grayscale(0);

}

.vertical_swiper.without_bullet_svg .swiper .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.without_bullet_svg .swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--Primary-color);
    height: 0.5rem;
    width: 0.5rem;
}

.without_bullet_svg .swiper .swiper-pagination-bullet {
    background-color: var(--Primary-color);
    height: 0.5rem;
    width: 0.5rem;
    margin: 0.4rem 0.4rem;
    position: relative;
    transition: all 0.2s ease-in-out;
}



/* .vertical_swiper .swiper .swiper-pagination-bullet::before {
    content: '';
    position: absolute;
    background-color: transparent;
    top: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
    right: -0.25rem;
    z-index: -1;
    border-radius: 100%;
    border: 0.12rem solid var(--Primary-color);
}  */

@media (min-width: 1024px) {

    /* #slider .swiper-slide{
        height: 70vh;
    } */
    .vertical_swiper .swiper {
        width: auto;
        height: 35rem;
        position: relative;
        /* background:; */
        padding: 2rem;
    }

    .vertical_swiper .swiper-slide {
        padding-top: 2rem;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .vertical_swiper .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .vertical_swiper .swiper .swiper-wrapper {
        margin-bottom: 0.75rem;
    }

    .vertical_swiper .swiper .swiper-button-prev {
        position: absolute;
        top: 5.8%;
        left: 47%;
        /* bottom: -40%; */
        /* left: 47%; */
        transform: rotate(90deg);
    }

    .vertical_swiper .swiper .swiper-button-next {
        position: sticky;
        bottom: -15%;
        left: 47%;
        transform: rotate(90deg);
    }

    .vertical_swiper.without_bullet_svg .swiper .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    #client_Comments .child_client_Comments .sub_child_client_Comments .swiper {
        /* position: relative; */
        /* width: 100vw; */
        padding: 0 2.4rem;
    }

}

/* #client_Comments .child_client_Comments .sub_child_client_Comments .swiper { */
/* position: relative; */
/* width: 100vw; */
/* padding: 0 2.4rem; */
/* padding: 0; */
/* } */

/* .swiper-wrapper{
    padding: 0 5rem;
} */

#client_Comments .child_client_Comments .sub_child_client_Comments .swiper .swiper-slide {
    padding: 0 2rem;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.swiper-button-next {
    background: var(--Deem-primary-color);
    color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
}

.swiper-button-prev {
    background: var(--Deem-primary-color);
    color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
}

.swiper-button-next::after {
    font-size: 1rem;
    font-weight: bold;
}

.swiper-button-prev::after {
    font-size: 1rem;
    font-weight: bold;
}





#OUR_CLIENTS .swiper-wrapper{
    margin:auto;
}
/* #OUR_CLIENTS .swiper-slide{
    width: 13rem !important;
} */

#OUR_CLIENTS .swiper-slide img{
    margin:auto;
}

#OUR_CLIENTS .swiper-slide.text-slide{
    /* width: auto !important; */
    font-size: 1rem;
    font-weight: bold;
    margin: auto;
}

#OUR_CLIENTS .swiper-slide div{
    padding-bottom: 2rem;
}




/* #app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */
  
